<template>
  <div>
    <div v-if="item[column]">
      <div v-if="type === 'document'">
        <document-cell
          :column="column"
          :item="item"
          :project-id="projectId"
          :show-preview="false"
          :table-id="tableId"
        />
      </div>
      <div v-else-if="type === 'link'">
        <a :href="item[column]" target="_blank">{{ item[column] }}</a>
      </div>
      <div v-else-if="type === 'text' || type === 'text-field'">
        <template v-if="diff">
          <div v-html="formattedString" />
        </template>
        <template v-else>
          {{ item[column] }}
        </template>
      </div>
      <div v-else>
        {{ item[column] }}
      </div>
    </div>
    <div v-else>Null</div>
  </div>
</template>

<script>
import DocumentCell from '@/components/DynamicDataTable/DocumentCell';

export default {
  name: 'TableRevisionsCell',
  components: { DocumentCell },
  props: {
    item: {
      type: Object,
      required: true,
    },
    column: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    diff: {
      type: Array,
      required: false,
      default: undefined,
    },
    viewMode: {
      type: String,
      required: false,
      default: undefined,
    },
    projectId: {
      type: String,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedString() {
      if (this.diff) {
        return this.diff.reduce((str, item) => {
          if (this.viewMode === 'current') {
            item.removed
              ? (str =
                  str +
                  `<span style="background-color: rgba(76,175,80,0.5)">${item.value}</span>`)
              : '';
          }
          if (this.viewMode === 'prev') {
            item.added
              ? (str =
                  str +
                  `<span style="background-color: rgba(255,82,82,0.5)">${item.value}</span>`)
              : '';
          }
          if (!item.removed && !item.added) {
            str += item?.value;
          }
          return str;
        }, '');
      }
      return this.item[this.column];
    },
  },
};
</script>

<style scoped></style>
