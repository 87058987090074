<template>
  <div
    v-if="tableSettingsStatus === 'success' && selectedProjectTable"
    class="d-flex flex-column flex-grow-1"
  >
    <div
      class="px-4 py-5 ant-glass-background radius-0 ant-border-bottom d-flex align-center"
    >
      <div class="d-flex">
        <v-edit-dialog
          :return-value.sync="selectedProjectTable.name"
          large
          @open="editTableName = selectedProjectTable.name"
          @save="updateTableName"
        >
          {{ selectedProjectTable.name }}
          <template #input>
            <v-text-field v-model="editTableName" label="Edit" single-line />
          </template>
          <v-icon class="ml-2" small> mdi-pencil</v-icon>
        </v-edit-dialog>
      </div>

      <v-spacer />
      <v-btn v-if="$can('delete', 'project-table')" icon small>
        <v-icon class="ant-icon" small @click="tableDeleteDialog = true">
          mdi-delete
        </v-icon>
      </v-btn>
    </div>

    <table-permissions
      class="ant-glass-background radius-0 ant-border-bottom"
    />

    <table-columns class="ant-glass-background radius-0 ant-border-bottom" />

    <table-relations class="ant-glass-background radius-0 ant-border-bottom" />

    <delete-dialog
      :dialog="tableDeleteDialog"
      :loading="$wait.is('table.delete')"
      title="Are you sure you want to delete this table?"
      @closeDialog="tableDeleteDialog = false"
      @deleteAction="deleteTable"
    />
  </div>

  <div v-else class="d-flex justify-center align-center flex-grow-1">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TablePermissions from '@/components/Project/Tables/Settings/TablePermissions';
import TableColumns from '@/components/Project/Tables/Settings/TableColumns';
import DeleteDialog from '@/components/DeleteDialog';
import TableRelations from '@/components/Project/Tables/Settings/TableRelations';
import AntLoading from '@/components/AntLoading';

export default {
  name: 'TableSettings',
  components: {
    AntLoading,
    TableRelations,
    DeleteDialog,
    TableColumns,
    TablePermissions,
  },
  data: () => {
    return {
      editTableName: undefined,
      tableDeleteDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'projectTables',
      'selectedProjectTable',
      'tableSettingsStatus',
    ]),
  },
  watch: {
    selectedProjectTable(table) {
      if (table) {
        this.fetchTableSettingsData();
      }
    },
  },
  mounted() {
    if (!this.selectedProjectTable) {
      this.$store.commit(
        'set_project_table',
        this.projectTables.find(
          (table) => table.id === this.$route.params.tableId
        )
      );
    } else {
      this.fetchTableSettingsData();
    }
  },
  methods: {
    fetchTableSettingsData() {
      this.$store.dispatch('fetchTableSettings', {
        projectId: this.selectedProjectTable.project,
        tableId: this.selectedProjectTable.id,
      });
    },
    updateTableName() {
      let body = {
        project: {
          id: this.selectedProjectTable.project,
        },
        name: this.editTableName,
      };

      this.$store.dispatch('updateTable', {
        tableId: this.selectedProjectTable.id,
        body,
      });
    },
    deleteTable() {
      let body = {
        project: {
          id: this.$store.getters.project.id,
        },
      };
      this.$store
        .dispatch('deleteTable', {
          tableId: this.selectedProjectTable.id,
          body,
        })
        .then(() => {
          this.$router.push({ name: 'tables' });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
