<template>
  <v-dialog
    v-model="dialog"
    max-width="90%"
    width="unset"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        Search in table
      </v-card-title>
      <v-divider />
      <div class="px-10 pb-5">
        <ant-input label="Search">
          <template #input-field>
            <div class="d-flex align-center">
              <v-text-field
                v-model="searchQueryData.searchPhrase"
                class="mr-5"
                dense
                filled
                hide-details
                label="Search"
                single-line
              />

              <v-switch
                v-model="searchQueryData.exactMatch"
                class="mt-0 pt-0"
                hide-details
                label="exact"
              />
            </div>
          </template>
        </ant-input>

        <ant-input label="Columns">
          <template #input-field>
            <v-select
              v-model="searchQueryData.columns"
              :items="tableColumns"
              dense
              filled
              hide-details
              item-text="name"
              item-value="name"
              label="Columns"
              multiple
              single-line
            />
          </template>
        </ant-input>
      </div>
      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn color="error" text @click="closeDialog"> Cancel</v-btn>
        <v-btn
          :disabled="
            searchQueryData.searchPhrase.length === 0 ||
            searchQueryData.columns.length === 0
          "
          color="primary"
          elevation="0"
          small
          @click="searchInTable"
        >
          Search
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'TableSearchDialog',
  components: { AntInput },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      searchQueryData: {
        searchPhrase: '',
        exactMatch: false,
        columns: [],
      },
    };
  },
  computed: {
    ...mapGetters(['selectedProjectTable', 'tableColumns']),
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    searchInTable() {
      let body = {
        project: {
          id: this.selectedProjectTable.project,
        },
        table: {
          id: this.selectedProjectTable.id,
        },
        search: {},
        searchFields: this.searchQueryData.columns,
      };

      if (this.searchQueryData.exactMatch) {
        body.search.exact = this.searchQueryData.searchPhrase;
      } else {
        body.search.phrase = this.searchQueryData.searchPhrase;
      }

      this.$emit('searchRecords', body);
      this.closeDialog();
    },
  },
};
</script>

<style scoped></style>
