<template>
  <v-dialog
    ref="dialog"
    v-model="display"
    :return-value.sync="dateTimeObject"
    persistent
    width="290px"
  >
    <template #activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on" name="date-time-input" />
    </template>
    <v-card>
      <v-date-picker
        v-model="dateTimeObject.date"
        :max="maxDate"
        :min="minDate"
        scrollable
      />
      <div
        :class="{ 'time-disabled': !dateTimeObject.date }"
        class="d-flex mx-2 justify-center"
      >
        <div class="time-selector">
          <v-icon v-ripple="false" large @click="setHours(1)">
            mdi-chevron-up
          </v-icon>
          <div class="time-value">
            {{
              dateTimeObject.time.hours > 9
                ? dateTimeObject.time.hours
                : `0${dateTimeObject.time.hours}`
            }}
          </div>
          <v-icon v-ripple="false" large @click="setHours(-1)">
            mdi-chevron-down
          </v-icon>
        </div>
        <div class="time-separator">:</div>
        <div class="time-selector">
          <v-icon v-ripple="false" large @click="setMinutes(1)">
            mdi-chevron-up
          </v-icon>
          <div class="time-value">
            {{
              dateTimeObject.time.minutes > 9
                ? dateTimeObject.time.minutes
                : `0${dateTimeObject.time.minutes}`
            }}
          </div>
          <v-icon v-ripple="false" large @click="setMinutes(-1)">
            mdi-chevron-down
          </v-icon>
        </div>
        <div class="time-separator">:</div>
        <div class="time-selector">
          <v-icon v-ripple="false" large @click="setSeconds(1)">
            mdi-chevron-up
          </v-icon>
          <div class="time-value">
            {{
              dateTimeObject.time.seconds > 9
                ? dateTimeObject.time.seconds
                : `0${dateTimeObject.time.seconds}`
            }}
          </div>
          <v-icon v-ripple="false" large @click="setSeconds(-1)">
            mdi-chevron-down
          </v-icon>
        </div>
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="display = false"> Cancel</v-btn>
        <v-btn color="primary" @click="setTimeStamp"> OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DateTimePicker',
  props: {
    activeDate: {
      type: String,
      default: null,
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
      display: false,
      dateTimeObject: {
        date: null,
        time: {
          hours: 0,
          minutes: 0,
          seconds: 0,
        },
      },
    };
  },
  watch: {
    display: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          if (this.activeDate) {
            let date = moment(this.activeDate);
            this.dateTimeObject.date = date.format('YYYY-MM-DD');
            this.dateTimeObject.time.hours = date.hours();
            this.dateTimeObject.time.minutes = date.minutes();
            this.dateTimeObject.time.seconds = date.seconds();
          }
        }
      },
    },
  },
  methods: {
    setHours(count) {
      this.dateTimeObject.time.hours += count;
      if (this.dateTimeObject.time.hours > 23) {
        this.dateTimeObject.time.hours = 0;
      } else if (this.dateTimeObject.time.hours < 0) {
        this.dateTimeObject.time.hours = 23;
      }
    },
    setMinutes(count) {
      this.dateTimeObject.time.minutes += count;
      if (this.dateTimeObject.time.minutes > 59) {
        this.dateTimeObject.time.minutes = 0;
      } else if (this.dateTimeObject.time.minutes < 0) {
        this.dateTimeObject.time.minutes = 59;
      }
    },
    setSeconds(count) {
      this.dateTimeObject.time.seconds += count;
      if (this.dateTimeObject.time.seconds > 59) {
        this.dateTimeObject.time.seconds = 0;
      } else if (this.dateTimeObject.time.seconds < 0) {
        this.dateTimeObject.time.seconds = 59;
      }
    },
    setTimeStamp() {
      let timestamp = moment(this.dateTimeObject.date).set({
        hour: this.dateTimeObject.time.hours,
        minute: this.dateTimeObject.time.minutes,
        second: this.dateTimeObject.time.seconds,
      });
      this.$emit('selectedTimeStamp', timestamp.format('YYYY-MM-DD HH:mm:ss'));
      this.display = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.time-disabled {
  pointer-events: none !important;
  opacity: 0.7;
  transition: 200ms;
}

.time-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  margin: 0 10px;
}

.time-selector {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  align-items: center;
  justify-content: center;
  width: 40px;

  .v-icon {
    cursor: pointer;

    &:hover {
      color: var(--v-primary-base);
    }
  }

  .time-value {
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    margin: 0 0.25rem;
    padding: 0.25rem 0;
    width: 100%;
    text-align: center;
  }
}
</style>
