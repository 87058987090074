<template>
  <div class="overflow-y-auto flex-1">
    <v-timeline clipped dense>
      <div
        v-for="revision in revisions"
        :key="revision.id"
        class="ant-revisions-item"
        @click.prevent="$emit('select', revision.id)"
      >
        <v-timeline-item :color="getRevisionColor(revision)" small>
          <div class="d-flex justify-space-between">
            <div class="d-flex flex-column pr-8">
              <div class="d-flex align-center">
                <span
                  class="flex-grow-1 font-italic"
                  style="font-size: 12px; white-space: nowrap"
                >
                  {{ formatDate(revision.timestamp) }}
                </span>
              </div>
              <div style="font-size: 10px">Version: {{ revision.version }}</div>
            </div>
          </div>
        </v-timeline-item>
      </div>

      <v-timeline-item
        class="mb-0"
        color="primary"
        fill-dot
        icon="mdi-table"
        icon-color="white"
      >
        <div class="d-flex flex-column">
          Table created
          <div class="font-italic" style="font-size: 10px">
            {{ formatDate(selectedProjectTable.created_at) }}
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'TableRevisionsList',
  props: {
    revisions: {
      type: Array,
      default: () => [],
      require: true,
    },
    revisionId: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // menu: false,
    };
  },
  computed: {
    ...mapGetters(['selectedProjectTable']),
  },
  methods: {
    getRevisionColor(revision) {
      if (revision.disabled) return 'red';
      return revision.id === this.revisionId ? 'primary' : 'grey';
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
  },
};
</script>

<style scoped></style>
