<template>
  <div :style="{ minWidth: fieldWidth() }" class="my-1">
    <v-form ref="form" @submit.prevent>
      <table-view-input
        v-if="column.type === 'text'"
        v-model="editedItem[columnName]"
        :is-updated="isUpdated"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        @blur="onEdit"
        @append-click="rollBack"
        @keyup.enter="onEdit"
      />

      <table-view-input
        v-else-if="column.type === 'integer'"
        v-model="editedItem[columnName]"
        :is-updated="isUpdated"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        type="number"
        @blur="onEdit"
        @append-click="rollBack"
        @keyup.enter="onEdit"
      />

      <table-view-input
        v-else-if="column.type === 'float'"
        v-model="editedItem[columnName]"
        :is-updated="isUpdated"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        type="number"
        @blur="onEdit"
        @append-click="rollBack"
        @keyup.enter="onEdit"
      />

      <table-view-input
        v-else-if="column.type === 'link'"
        v-model="editedItem[columnName]"
        :is-updated="isUpdated"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        @blur="onEdit"
        @append-click="rollBack"
        @keyup.enter="onEdit"
      />

      <table-view-input
        v-else-if="column.type === 'email'"
        v-model="editedItem[columnName]"
        :is-updated="isUpdated"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName]), rules.email]"
        dense
        @blur="onEdit"
        @append-click="rollBack"
        @keyup.enter="onEdit"
      />

      <v-textarea
        v-else-if="column.type === 'text-field'"
        v-model="editedItem[columnName]"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        no-resize
        rows="1"
        type="text"
        @blur="onEdit"
        @focus="onFocus"
      >
        <template v-if="isUpdated && !loading" #append>
          <v-tooltip bottom>
            <template #activator="{ on: onAppendTooltip, attrs: appendAttrs }">
              <v-btn
                icon
                small
                v-bind="appendAttrs"
                @click="rollBack"
                v-on="onAppendTooltip"
              >
                <v-icon class="ant-icon" dense> mdi-arrow-u-left-top</v-icon>
              </v-btn>
            </template>
            <span>Rollback</span>
          </v-tooltip>
        </template>
        <template #message="{ message }">
          <v-tooltip :value="message && hasFocus" bottom color="error">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" />
            </template>
            <span>{{ message }}</span>
          </v-tooltip>
        </template>
      </v-textarea>

      <v-checkbox
        v-else-if="column.type === 'boolean'"
        ref="edited-cell"
        v-model="editedItem[columnName]"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        @change="onEdit"
      >
        <template #message="{ message }">
          <v-tooltip :value="message && hasFocus" bottom color="error">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" />
            </template>
            <span>{{ message }}</span>
          </v-tooltip>
        </template>
      </v-checkbox>

      <v-menu
        v-else-if="column.type === 'date'"
        ref="edited-cell"
        v-model="dateMenu"
        :close-on-content-click="false"
        close-on-click
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template #activator="{ on, attrs }">
          <table-view-input
            v-model="editedItem[columnName]"
            :clearable="!Boolean(column.required)"
            :is-updated="isUpdated"
            :loading="$wait.is('table.record.update')"
            :placeholder="column.name"
            :rules="[rules.required(column, editedItem[columnName])]"
            dense
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            @blur="onEdit"
            v-on="on"
            @append-click="rollBack"
            @keyup.enter="onEdit"
          />
        </template>
        <v-date-picker
          v-model="editedItem[columnName]"
          @input="
            () => {
              dateMenu = false;
              onEdit();
            }
          "
        />
      </v-menu>

      <v-select
        v-else-if="column.type === 'dropdown'"
        v-model="editedItem[columnName]"
        :items="JSON.parse(column.options_value)"
        :menu-props="{ bottom: true, offsetY: true }"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        @change="onEdit"
        @focus="onFocus"
      >
        <template #message="{ message }">
          <v-tooltip :value="message && !hasFocus" bottom color="error">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" />
            </template>
            <span>{{ message }}</span>
          </v-tooltip>
        </template>
      </v-select>

      <v-select
        v-else-if="column.type === 'table'"
        ref="edited-cell"
        v-model="editedItem[columnName]"
        :clearable="!Boolean(column.required)"
        :items="projectTables"
        :menu-props="{ bottom: true, offsetY: true }"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        item-text="name"
        item-value="id"
        @blur="onEdit"
        @change="onEdit"
        @focus="onFocus"
      >
        <template #message="{ message }">
          <v-tooltip
            :value="message && !hasFocus && focusCount"
            bottom
            color="error"
          >
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" />
            </template>
            <span>{{ message }}</span>
          </v-tooltip>
        </template>
      </v-select>

      <v-file-input
        v-else-if="column.type === 'document'"
        ref="edited-cell"
        v-model="editedItem[columnName]"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        @blur="onEdit"
        @focus="onFocus"
      >
        <template #message="{ message }">
          <v-tooltip
            :value="message && !hasFocus && focusCount"
            bottom
            color="error"
          >
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" />
            </template>
            <span>{{ message }}</span>
          </v-tooltip>
        </template>
      </v-file-input>

      <v-combobox
        v-else-if="column.type === 'sbscode'"
        v-model="editedItem[columnName]"
        :items="sbsRecords"
        :placeholder="column.name"
        :rules="[rules.required(column, editedItem[columnName])]"
        dense
        item-text="code"
        item-value="code"
        type="text"
        @blur="onEdit"
        @change="onEdit"
        @focus="onFocus"
      >
        <template #item="{ item: sbsItem }">
          <div v-if="sbsItem.label" class="d-flex align-center">
            {{ sbsItem.label }}
            <v-divider class="mx-2" vertical />
            <span style="font-size: 11px; color: grey">
              {{ sbsItem.code }}
            </span>
          </div>
          <div v-else>
            {{ sbsItem.code }}
          </div>
        </template>

        <template v-if="isUpdated && !loading" #append>
          <v-tooltip bottom>
            <template #activator="{ on: onAppendTooltip, attrs: appendAttrs }">
              <v-btn
                icon
                small
                v-bind="appendAttrs"
                @click="rollBack"
                v-on="onAppendTooltip"
              >
                <v-icon class="ant-icon" dense> mdi-arrow-u-left-top</v-icon>
              </v-btn>
            </template>
            <span>Rollback</span>
          </v-tooltip>
        </template>
        <template #message="{ message }">
          <v-tooltip
            :value="message && !hasFocus && focusCount"
            bottom
            color="error"
          >
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" />
            </template>
            <span>{{ message }}</span>
          </v-tooltip>
        </template>
      </v-combobox>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import TableViewInput from '@/components/Project/Tables/TableView/TableViewInput.vue';
import DataHelper from '@/services/data-helper';

export default {
  name: 'TableViewEditableCell',
  components: { TableViewInput },
  props: {
    item: {
      type: Object,
      required: true,
    },
    columnName: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    rules: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editedItem: {},
      dateMenu: false,
      hasFocus: false,
      focusCount: 0,
    };
  },
  computed: {
    ...mapGetters(['tableColumns', 'projectTables', 'sbsRecords']),
    column() {
      return this.tableColumns.find((c) => c.name === this.columnName);
    },
    isUpdated() {
      const initial = DataHelper.parseData(
        this.column.type,
        this.item[this.columnName],
        this.column.required
      );
      const compare = DataHelper.parseData(
        this.column.type,
        this.editedItem[this.columnName],
        this.column.required
      );
      return initial !== compare;
    },
  },
  watch: {
    item: {
      handler(v) {
        this.editedItem = cloneDeep(v);
      },
    },
    deep: true,
    immediate: true,
    hasFocus(value) {
      if (!value) {
        setTimeout(() => {
          this.focusCount = 0;
        }, 1800);
      }
    },
  },
  async mounted() {
    this.editedItem = cloneDeep(this.item);
  },
  methods: {
    fieldWidth() {
      switch (this.column.type) {
        case 'boolean':
          return 0;
        case 'integer':
        case 'float':
          return '100px';
        default:
          return '200px';
      }
    },
    onEdit() {
      this.onBlur();
      if (this.$refs.form.validate()) {
        if (!this.isUpdated) return;
        this.$emit('set-item', this.editedItem);
      }
    },
    onFocus() {
      this.hasFocus = true;
      this.focusCount++;
    },
    onBlur() {
      this.hasFocus = false;
    },
    rollBack() {
      this.editedItem[this.columnName] = this.item[this.columnName];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__details,
::v-deep .v-messages {
  height: 0;
  overflow: hidden;
  min-height: 0;
}
</style>
