<template>
  <div class="flex-grow-1">
    <div class="white flex-grow-1 pa-5 d-flex flex-column">
      <div class="full-width fs-20 pb-5 d-flex">
        Tables Query Maker
        <v-spacer />
        <v-btn
          :loading="$wait.is('tables.queryGraph.export')"
          class="mr-2"
          color="primary"
          elevation="0"
          small
          @click="queryTableExport"
        >
          CSV
        </v-btn>
        <v-btn color="primary" elevation="0" small @click="queryTable">
          Query
        </v-btn>
      </div>
      <div class="d-flex flex-grow-1 overflow-y-auto">
        <div class="flex-grow-1 mr-5 d-flex flex-column">
          <div
            v-for="(table, tableIndex) in selectedTablesArray"
            :key="tableIndex"
          >
            <div
              class="mb-8 radius-8 overflow-hidden ant-border d-flex flex-column"
            >
              <div class="d-flex pa-3 blue-grey lighten-5">
                <div class="whitespace-nowrap mr-6 d-flex align-center">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon class="mr-2" v-bind="attrs" v-on="on">
                        mdi-table
                      </v-icon>
                    </template>
                    <span>Table '{{ table.name }}'</span>
                  </v-tooltip>
                  <span class="font-weight-bold mr-6">{{ table.name }}</span>
                  as
                </div>
                <v-text-field
                  :key="`t-alias-${tableIndex}`"
                  :value="getTableAlias(table)"
                  dense
                  hide-details
                  label="Alias"
                  required
                  solo
                  @input="setTableAlias({ table, alias: $event })"
                />
                <div class="d-flex align-center justify-center ml-6">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        @click="
                          $store.dispatch('tablesQuery/addSearchColumn', table)
                        "
                        v-on="on"
                      >
                        <v-icon dense> mdi-table-column-plus-after</v-icon>
                      </v-btn>
                    </template>
                    <span>Add column</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="pa-2">
                <div
                  v-for="(column, columnIndex) in getTableColumns(table.id)"
                  :key="columnIndex"
                  :class="{
                    'ant-border-bottom':
                      columnIndex < getTableColumns(table.id).length - 1,
                    'mb-5': columnIndex < getTableColumns(table.id).length - 1,
                  }"
                  class="d-flex pb-5"
                >
                  <div class="d-flex flex-column align-center justify-center">
                    <v-checkbox
                      :key="`${table.id}-c${columnIndex}`"
                      v-model="column.active"
                      :ripple="false"
                      class="ma-0 pa-0"
                      hide-details
                    />
                  </div>
                  <div class="d-flex flex-grow-1 d-flex flex-column">
                    <div class="d-flex align-center">
                      <ant-input
                        class="flex-2"
                        label="Column"
                        top-margin="mt-0"
                      >
                        <template #input-field>
                          <v-select
                            :key="`${table.id}-c${columnIndex}-name`"
                            v-model="column.name"
                            :disabled="!column.active"
                            :item-disabled="
                              isColumnActiveInQuery(table.id, column.name)
                            "
                            :items="tableColumns[table.id]"
                            dense
                            filled
                            hide-details
                            item-text="name"
                            item-value="name"
                            label="Column"
                            single-line
                          />
                        </template>
                      </ant-input>
                      <div class="mx-6 ant-input-header-height">as</div>
                      <ant-input
                        class="flex-2"
                        is-optional
                        label="Alias"
                        top-margin="mt-0"
                      >
                        <template #input-field>
                          <v-text-field
                            v-model="column.as"
                            :disabled="!column.active || !column.name"
                            clearable
                            dense
                            filled
                            hide-details
                            label="Alias"
                            single-line
                          />
                        </template>
                      </ant-input>
                      <div
                        class="d-flex ant-input-header-height justify-center"
                        style="width: 100px"
                      >
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              :ripple="false"
                              icon
                              v-bind="attrs"
                              @click="
                                removeColumn({ table, index: columnIndex })
                              "
                              v-on="on"
                            >
                              <v-icon> mdi-table-column-remove</v-icon>
                            </v-btn>
                          </template>
                          <span>Remove column</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div
                      v-for="(condition, conditionIndex) in column.conditions"
                      :key="`${table.id}-c${columnIndex}-cond${conditionIndex}`"
                      class="d-flex align-center mt-5"
                    >
                      <template v-if="column.conditions.length > 1">
                        <ant-input
                          class="flex-2"
                          is-optional
                          label="Boolean"
                          top-margin="mt-0"
                        >
                          <template #input-field>
                            <v-select
                              v-model="condition.boolean"
                              :items="['and', 'or']"
                              dense
                              filled
                              hide-details
                              item-text="name"
                              item-value="name"
                              label="Boolean"
                              single-line
                            />
                          </template>
                        </ant-input>
                        <div class="mx-6 mt-5 white--text">--</div>
                      </template>
                      <ant-input
                        class="flex-2"
                        is-optional
                        label="Operator"
                        top-margin="mt-0"
                      >
                        <template #input-field>
                          <v-select
                            v-model="condition.operator"
                            :disabled="!column.active || !column.name"
                            :items="operators"
                            clearable
                            dense
                            filled
                            hide-details
                            label="Operator"
                            single-line
                          />
                        </template>
                      </ant-input>
                      <div class="mx-6 mt-5 white--text">--</div>
                      <ant-input
                        class="flex-2"
                        is-optional
                        label="Value"
                        top-margin="mt-0"
                      >
                        <template #input-field>
                          <v-text-field
                            v-model="condition.value"
                            :disabled="
                              !column.active ||
                              !column.name ||
                              !condition.operator
                            "
                            clearable
                            dense
                            filled
                            hide-details
                            label="Value"
                            single-line
                          >
                          </v-text-field>
                        </template>
                      </ant-input>
                      <div
                        class="d-flex ant-input-header-height justify-center"
                        style="width: 100px"
                      >
                        <v-btn
                          class="ml-2"
                          elevation="0"
                          rounded
                          small
                          @click="addCondition({ table, index: columnIndex })"
                        >
                          ADD
                        </v-btn>
                        <v-btn
                          v-if="column.conditions.length > 1"
                          class="ml-2"
                          icon
                          small
                          @click="
                            removeCondition({
                              table,
                              index: columnIndex,
                              conditionIndex,
                            })
                          "
                        >
                          <v-icon small> mdi-trash-can</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1 pos-rel">
          <pre
            class="fs-14 font-weight-bold leading-16 blue-grey lighten-5 pa-2"
            >{{ JSON.stringify(queryObject, null, 2) }}
          </pre>
          <v-btn
            style="top: 5px; right: 5px; position: absolute"
            @click="copyQueryJson"
            >COPY
          </v-btn>
        </div>
      </div>
    </div>
    <div class="ant-glass-background ant-border-top radius-0">
      <div
        v-if="$wait.is('tables.queryGraph.get')"
        class="flex-grow-1 d-flex align-center justify-center"
      >
        <ant-loading />
      </div>
      <div v-else>
        <dynamic-data-table
          v-for="tableAlias in tablesRecordsTables"
          :key="`records-table-${tableAlias}`"
          :table-columns="getRecordsTableColumns(tableAlias)"
          :table-headers="getRecordsTableHeaders(tableAlias)"
          :table-records="getRecordsTableRecords(tableAlias)"
          :table-title="`Query Results - ${tableAlias}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';
import AntLoading from '@/components/AntLoading.vue';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'TableQueryGraph',
  components: { AntInput, AntLoading, DynamicDataTable },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('tablesQuery/clear');
    next();
  },
  data: () => {
    return {
      operators: ['=', '>', '<', '>=', '<=', '<>'],
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      selectedTablesArray: 'tablesQuery/selectedTablesArray',
      tableColumns: 'tablesQuery/tableColumns',
      getTableQuery: 'tablesQuery/getTableQuery',
      queryObject: 'tablesQuery/queryObject',
      tablesRecords: 'tablesQuery/tablesRecords',
      tablesRecordsTables: 'tablesQuery/tablesRecordsTables',
    }),
  },
  methods: {
    copyQueryJson() {
      navigator.clipboard.writeText(JSON.stringify(this.queryObject, null, 2));
      this.$store.commit('showNotification', {
        content: `Copied body to clipboard`,
        color: 'success',
      });
    },
    isColumnActiveInQuery(tableId, columnName) {
      return (item) => {
        let activeColumns =
          this.queryObject?.tables?.find((table) => table.tableId === tableId)
            ?.columns || [];

        return (
          activeColumns.some((column) => column.name === item.name) ||
          item.name === columnName
        );
      };
    },
    addCondition({ table, index }) {
      return this.$store.commit('tablesQuery/query_columns_condition_add', {
        table,
        index,
      });
    },
    removeCondition({ table, index, conditionIndex }) {
      return this.$store.commit('tablesQuery/query_columns_condition_remove', {
        table,
        index,
        conditionIndex,
      });
    },
    getTableAlias(table) {
      return this.getTableQuery(table.id)?.as || table.id;
    },
    setTableAlias({ table, alias }) {
      this.$store.dispatch('tablesQuery/setTableAlias', {
        tableId: table.id,
        alias,
      });
    },
    removeColumn({ table, index }) {
      this.$store.dispatch('tablesQuery/removeSearchColumn', {
        table,
        index,
      });
    },
    getTableColumns(tableId) {
      return this.getTableQuery(tableId)?.columns || [];
    },
    getRecordsTableRecords(tableAlias) {
      return this.tablesRecords[tableAlias]?.records || [];
    },
    getRecordsTableColumns(tableAlias) {
      return Object.keys(
        this.getRecordsTableRecords(tableAlias)?.[0] || {}
      ).filter((c) => c !== 'id');
    },
    getRecordsTableHeaders(tableAlias) {
      return this.getRecordsTableColumns(tableAlias).map((c) => ({
        text: c,
        value: c,
      }));
    },
    queryTable() {
      this.$store.dispatch('tablesQuery/fetchTableQueryRecords', {
        projectId: this.project.id,
        body: this.queryObject,
      });
    },
    queryTableExport() {
      this.$store.dispatch('tablesQuery/runExportTableQueryRecords', {
        projectId: this.project.id,
        body: this.queryObject,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
