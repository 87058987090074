<template>
  <v-menu v-model="menu">
    <template #activator="{ on, attrs }">
      <template v-if="$scopedSlots.activate">
        <slot name="activate" v-bind="{ attrs, on }" />
      </template>
      <div v-else class="d-flex items-end" v-bind="attrs" v-on="on">
        <div v-if="selectedRevision" class="d-flex">
          <v-menu v-if="selectedRevision.reason" bottom offset-y open-on-hover>
            <template #activator="{ on: info, attrs: infoAttrs }">
              <v-btn
                class="ma-1"
                exact
                icon
                small
                v-bind="infoAttrs"
                v-on="info"
              >
                <v-icon> mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <div class="pa-2 ant-glass-background">
              <div class="d-flex flex-column">
                <div>Reason:</div>
                <div>
                  {{ selectedRevision.reason || 'No reason' }}
                </div>
              </div>
            </div>
          </v-menu>
          <div v-else class="pl-2"></div>
          <div class="pr-2">
            <div class="font-italic" style="font-size: 12px">
              {{ formatDate(selectedRevision.timestamp) }}
            </div>
            <div style="font-size: 10px">
              <span>Version: </span>
              <span>{{ selectedRevision.version }}</span>
            </div>
          </div>
          <v-tooltip v-if="$can('update', 'project-table')" bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="selectedRevision.id === '-1'"
                class="ma-1"
                exact
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click.stop="$emit('restore', selectedRevision.id)"
              >
                <v-icon color="primary"> mdi-source-pull</v-icon>
              </v-btn>
            </template>
            <span>Restore to here</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <table-revisions-list
      :revision-id="revisionId"
      :revisions="sortedRevisions"
      class="ant-panel--white"
      @restore="(id) => $emit('restore', id)"
      @select="(id) => $emit('select', id)"
    />
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import TableRevisionsList from '@/components/Project/Tables/TableRevisionsList.vue';

export default {
  name: 'TableRevisionsSelector',
  components: { TableRevisionsList },
  props: {
    revisionId: {
      type: String,
      default: '',
    },
    before: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters(['tableRevisions', 'selectedProjectTable']),
    revisionWithActual() {
      let array = [...this.tableRevisions];
      array.push({
        id: '-1',
        version: 'Actual',
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
      });
      return array;
    },
    revisions() {
      return this.revisionWithActual.filter(
        (revision) => !this.isDisabled(revision)
      );
    },
    sortedRevisions() {
      return [...this.revisions].sort((a, b) =>
        moment(b.timestamp).diff(moment(a.timestamp))
      );
    },
    selectedRevision() {
      if (!this.revisionId) return null;
      return this.sortedRevisions.find(
        (revision) => revision.id === this.revisionId
      );
    },
  },
  methods: {
    isDisabled(revision) {
      return this.before > 0
        ? moment(revision.timestamp, 'YYYY-MM-DD HH:mm:ss').unix() >
            this.before || revision.id === '-1'
        : false;
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
  },
};
</script>

<style lang="scss">
.v-timeline {
  padding: 0 0 10px;

  .ant-revisions-item {
    cursor: pointer;
    position: relative;

    &:before {
      background-color: currentColor;
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    &:hover {
      &:before {
        opacity: 0.1;
      }
    }
  }

  .v-timeline-item {
    min-height: 60px;
    padding: 10px 0;
    align-items: center;
  }
}
</style>
